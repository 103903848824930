<template>
    <div class="topic">
        <BackBar :pageTit="headTit" />
        <vue-scroll class="main-page" :ops="ops"
        @refresh-start="handleRefresh"
        @load-start="handleLoad"
        ref="vs"
        >
            <div class="user-wp" :style="{'background-image':'url('+user.user_zone_bg+')'}">
                <!-- <div class="edit"><img src="~@/assets/img/edit.png"></div> -->
                <div class="user-box">
                    <div class="avatar" :style="{'background-image':'url('+user.headimgurl+')'}"></div>
                    <div class="user_info">
                        <div>{{user.title}}</div>
                        <!--<p><span>标签</span><span>标签</span></p>-->
                    </div>
                </div>
                <div class="data_num">
                    <div class="data_fl">
                        <div><p>{{user.total.follow_child}}</p>关注</div>
                        <div><p>{{user.total.follow_parent}}</p>粉丝</div>
                        <div><p>{{user.total.post}}</p>发帖</div>
                    </div>
                    <div class="edit_info"  v-if="user.isme" @click="$router.push('/Seting')">编辑资料</div>
                    
                    <div class="btns" v-else>
                        <div>
                           <FollowBtn :user_id="user.id" :is_follow="user.isFollow" :is_big="true" />
                        </div>
                        <div @click="$router.push('/MsgRoom/'+user.id)">私信</div>
                    </div>
                </div>

            </div>
            <div class="tab">
                <div :class="{on:postType==0}" @click="changePostType(0)">帖子</div>
                <div :class="{on:postType==1}" @click="changePostType(1)">收藏</div>
            </div>

            <PostItem :hideUserBar="postType==0" v-for="item in posts"  :isDescPage="false" :key="'posts'+item.id" :item="item" />
        </vue-scroll>

    </div>
</template>

<script>
import BackBar from '@/components/BackBar.vue'
import Api from '@/api'
import Vue from 'vue'
import PostItem from '@/components/PostItem.vue'
import FollowBtn from '@/components/FollowBtn.vue'
export default {
  name: 'User',
  data(){
    const ops = {
      vuescroll: {
        mode: 'slide',
        pullRefresh: {
          enable: true
        },
        pushLoad: {
          enable: true,
        }
      }
    };
   
      ops.vuescroll.pullRefresh.tips = {
        deactive: '下拉刷新',
        active: '释放刷新',
        start: '刷新中...',
        beforeDeactive: '刷新成功!'
      };
      ops.vuescroll.pushLoad.tips = {
        deactive: '上拉加载',
        active: '释放加载',
        start: '加载中...',
        beforeDeactive: '加载成功!'
      };
    

    return {
      posts:[],
      postType:0,
      page:1,
       ops:ops,
       isloading:false,
       user:{},
       headTit:'个人中心'
    }
  },
  components: {
    BackBar,
    PostItem,
    FollowBtn
  },
  methods:{
    changePostType(v){
      this.postType=v;
      this.page=1;
      this.posts=[];
      this.refreshPost()
    },
    handleRefresh(vm, refreshDom, done){
      if(this.isloading){return;}
      console.log('refresh')
      this.page=1;
      this.posts=[];
      this.refreshPost(done)
    },
    handleLoad(vm, refreshDom, done){
      if(this.isloading){return;}
      console.log('handleLoad')
      this.refreshPost(done)
    },
    refreshPost(cb){
      var that=this
      this.isloading=true
      var url=this.postType==0?'posts_by_user':'fav_by_user';
      Api.go(url,{page:this.page++,id:this.$route.params.id},(res)=>{
        var _=that.posts
        if(res.length>0){
          for(var i in res){
            _.push(res[i])
          }
        }else{
          Vue.prototype.$toast('暂无更多')
        }
        if(cb){cb();}
        that.isloading=false;
        setTimeout(() => {
          that.$refs.vs.refresh();
        }, 100)
      }) 
    }
  },
  mounted(){
      
    this.refreshPost()
    var that=this
    Api.go('user',{id:this.$route.params.id},(res)=>{
      Vue.set(that,'user',res)
    })
  }
}
</script>

<style lang="less" scoped>
.topic{padding-bottom: 1rem;position: fixed;left: 0;top:0;width: 100%;height: 100%;box-sizing: border-box;}
/* .topic h2{line-height: 1rem;text-align: center;} */
/* .newpost-btn{position: fixed;display: block;
bottom: 1.4rem;right: 0.2rem;height: 1.14rem;width:1.14rem;
background: url(~@/assets/img/home-add.png) no-repeat center center/cover;} */
.user-wp{
    width: 100%;
    height: 3.64rem;
    background:url(~@/assets/img/center-head.png) no-repeat center center/cover;
    position: relative;
    padding: 0 .32rem;
    box-sizing: border-box;
    .edit{
        padding: .2rem;
        position: absolute;
        top: .12rem;
        right: .12rem;
        img{
            width: .4rem;
            height: .4rem;
            display: block;
        }
    }
    .user-box{
       display: flex;
       align-items: center;
       padding-top: .6rem;
       .avatar{
            width: 1.12rem;
            height: 1.12rem;
            border-radius: 50%;
            background-size: cover;
            margin-right: .26rem;
       }
       .user_info{
           div{
               font-size: .32rem;
               color: #fff;
               font-weight: bold;
               margin-bottom: .14rem;
           }
           p{
               display: flex;
               align-items: center;
               span{
                   background: rgba(255, 255, 255, .4);
                   border-radius: .24rem;
                   padding:0 .24rem;
                   font-size: .24rem;
                   color: #F65102;
                   margin-right: .16rem;
                    &:last-child{
                        color: #2085FB;
                        margin-right: 0;
                    }
               }
           }
       }
    }
    .data_num{
        margin-top: .78rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .data_fl{
            display: flex;
            align-items: center;
            div{
                font-size: .24rem;
                color: #fff;
                margin-right: .24rem;
                text-align: center;
                p{
                    font-size: .28rem;
                    font-weight: bold;
                }
            }
        }
        .btns{
            display: flex;
            align-items: center;
            div{
                width: 1.2rem;
                height: .6rem;
                line-height: .6rem;
                background: #F65102;
                border-radius: .3rem;
                font-size: .28rem;
                color: #fff;
                text-align: center;
                margin-right: .32rem;
                &:last-child{
                    background: #338AF1;
                    margin-right: 0;
                }
            }
        }
        .edit_info{
            width: 1.8rem;
            height: .6rem;
            line-height: .6rem;
            border-radius: .3rem;
            border: 0.02rem solid #FFFFFF;
            font-size: .28rem;
            color: #fff;
            text-align: center;
        }
    }
}
.tab{
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: space-around;
    div{
        font-size: .32rem;
        color: #666;
        padding: .2rem 0;
        border-bottom: 1px solid transparent;
        &.on{
            color: #F65102;
            border-bottom: 1px solid #F65102;
        }
    }
}

</style>